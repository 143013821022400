/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import { Reset } from '../utils/style'
import Layout from './main'
import SEO from '../components/SEO'

export default ({ children, path, pageContext, ...rest }) => {
  const crumbs = [
    {
      title: 'kristo',
      slug: '/',
    },
    {
      title: pageContext.frontmatter.title,
      slug: `${path}`,
    },
  ]
  return (
    <Layout crumbs={crumbs} background="normal">
      <SEO title={pageContext.frontmatter.title} />
      <Styled.root sx={{ variant: 'textStyles.post' }}>
        <div sx={{ display: 'flex', justifyContent: 'center' }}>
          <div sx={{ maxWidth: 708, overflow: 'hidden', px: [3, 3, 5] }}>
            <Reset />
            {children}
          </div>
        </div>
      </Styled.root>
    </Layout>
  )
}
